import React from 'react';
import Showcase from '../components/Showcase';
import ChooseUs from '../components/ChooseUs';
import Courses from '../components/Courses';
import Partners from '../components/Patners';
import Testimonials from '../components/Testimonials';

 const Home = () => {
  return (
    <div>
      <Showcase/>
      <ChooseUs/>
      <Partners/>
      <Courses/>
      <Testimonials/>
    </div>
  )
};
export default Home;