import React from 'react';
import { Youtube, Instagram } from 'lucide-react';

const Showcase = () => {
  return (
    <section className=" relative bg-mobile bg-cover bg-center h-screen sm:bg-desktop flex items-center justify-center md:justify-start filter backdrop-blur-lg">
  <div className="container mx-auto px-4 relative z-10 font-mono">
    <div className="text-center md:text-left"> {/* Centré sur mobile, aligné à gauche pour PC */}
      <h1 className="text-4xl md:text-5xl font-bold mb-4 text-white">
        Digital English Learning Academy
      </h1>
      <p className="text-xl mb-8 text-white">
        Simplify your speaking in prestigious ways!
      </p>
      <a 
        href="https://form.jotform.com/231866803840359" 
        className=" bg-orange-600 hover:bg-orange-800 text-white font-bold py-2 px-4 rounded inline-block transition duration-300"
      >
        Register for Classes
      </a>
      <div className="my-8 border-t border-white w-1/6 mx-auto md:mx-0"></div>
      <div className="flex justify-center md:justify-start space-x-4"> {/* Icônes centrées sur mobile */}
        <a href="https://www.instagram.com/dela96910?igsh=OGFtZTF0bm1lNWxr" target="_blank" rel="noopener noreferrer" className="text-red-900 sm:text-white">
          <Instagram size={34} />
        </a>
        <a href="https://www.youtube.com/@DigitalEnglishLanguageAcademy" target="_blank" rel="noopener noreferrer" className="text-red-900 sm:text-white">
          <Youtube size={34} />
        </a>
      </div>
    </div>
  </div>
</section>

  
  );
};

export default Showcase;