import React from 'react';
import { Youtube, Instagram } from 'lucide-react';

const Footer = () => {
  return (
    <footer className="bg-rose-950 text-white py-8 font-mono">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 text-center md:text-left">
          <div className="footer-section">
            <h3 className="text-xl font-bold mb-4">Contact Us</h3>
            <p className="mb-2">Email: info.@delacademy.online</p>
            <p>Phone: +49 176 220 29283</p>
          </div>
          <div className="footer-section">
            {/* This section is intentionally left blank as per the original HTML */}
          </div>
          <div className="footer-section">
            <h3 className="text-xl font-bold mb-4">Follow Us</h3>
            <div className="flex justify-center md:justify-start space-x-4 mb-4">
              <a href="https://www.youtube.com/@DigitalEnglishLanguageAcademy" className="hover:text-gray-300"><Youtube size={24} /></a>
              <a href="https://www.instagram.com/dela96910?igsh=OGFtZTF0bm1lNWxr" className="hover:text-gray-300"><Instagram size={24} /></a>
            </div>
            <a 
              href="https://form.jotform.com/231866803840359" 
              className="inline-block bg-orange-600 hover:bg-orange-800 text-white font-bold py-2 px-4 rounded transition duration-300"
            >
              Register for Classes
            </a>
          </div>
        </div>
      </div>
      <div className="container mx-auto px-4 mt-8 pt-4 border-t border-gray-700">
        <p className="text-center text-sm">
          &copy; {new Date().getFullYear()} Digital English Learning Academy. All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;