import React from 'react'

const Resources = () => {
  return (
    <section id="resources" className="py-16">
      <div className="container mx-auto px-4 font-mono">
          <h2 className='text-2xl md:text-3xl underline underline-offset-8 font-bold text-center mb-12'>Resources</h2>
      </div>
    </section>
    
  )
};
export default Resources;
