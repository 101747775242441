import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Partners = () => {
  // Ces URL sont des placeholders. Remplacez-les par les vraies URLs de vos logos de partenaires.
  const partnerLogos = [
    '/images/logo.png',
    '/images/ilead.png',
    '/images/GILEAD.png',
    '/images/ilead.png',
    
  ];

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  return (
    <section className="py-16 bg-white">
  <div className="container mx-auto px-4">
    <h2 className="text-2xl md:text-3xl font-mono font-bold text-center mb-12 underline underline-offset-8">Partners</h2>
    <Slider {...settings}>
      {partnerLogos.map((logo, index) => (
        <div key={index} className="flex justify-center px-4">
          <img 
            src={logo} 
            alt={`Partner ${index + 1}`} 
            className="mx-auto h-32 w-32 object-contain" // Taille uniforme
          />
        </div>
      ))}
    </Slider>
  </div>
</section>

  );
};

export default Partners;