import React from 'react';

const Courses = () => {
  const courseList = [
    {
      title: "Improving Speaking Aptitude",
      description: "Enhance your English speaking skills through interactive sessions and practice."
    },
    {
      title: "Cambridge and IELTS Preparation",
      description: "Get ready for international English exams with our targeted preparation courses."
    },
    {
      title: "General English Language Classes",
      description: "Comprehensive English courses for all levels from A1 to C1."
    }
  ];

  return (
    <section className="py-10 bg-gray-100 font-mono">
      <div className="container mx-auto px-4">
        <h2 className="text-2xl md:text-3xl font-bold text-center mb-12  text-red-900 underline underline-offset-8">Courses</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {courseList.map((course, index) => (
            <div key={index} className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold mb-4">{course.title}</h3>
              <p>{course.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Courses;