import React from 'react';

const ChooseUs = () => {
  const reasons = [
    {
      title: "Expert Instructors",
      description: "Our team consists of experienced, native English speakers who are passionate about teaching."
    },
    {
      title: "Flexible Learning",
      description: "Study anytime, anywhere with our online platform designed to fit your busy schedule."
    },
    {
      title: "Personalized Approach",
      description: "We tailor our courses to meet your specific needs and learning goals."
    },
    {
      title: "Interactive Sessions",
      description: "Engage in dynamic, interactive lessons that make learning English fun and effective."
    }
  ];

  return (
    <section className="py-10 bg-gray-100 font-mono">
      <div className="container mx-auto px-4">
        <h2 className="text-2xl md:text-3xl font-bold text-center mb-12 text-red-900 underline underline-offset-8 ">Why Choose Us</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {reasons.map((reason, index) => (
            <div key={index} className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold mb-4 text-green-900">{reason.title}</h3>
              <p>{reason.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ChooseUs;