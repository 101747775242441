import React from 'react';

const Testimonials = () => {
  const testimonialsList = [
    {
      content: "DELA has been a game-changer for my English skills. The instructors are knowledgeable and patient, and the online platform is user-friendly. I've seen significant improvement in my speaking and writing abilities.",
      author: "Sarah K., IELTS Student"
    },
    {
      content: "I was skeptical about online language learning at first, but DELA exceeded my expectations. The personalized attention and interactive lessons have boosted my confidence in speaking English. Highly recommended!",
      author: "Miguel R., General English Student"
    },
    {
      content: "Preparing for the Cambridge exam was daunting, but with DELA's structured course and expert guidance, I felt well-prepared and achieved my target score. Thank you, DELA!",
      author: "Yuki T., Cambridge Exam Student"
    }
  ];

  return (
    <section className="py-16 bg-gray-100 font-mono">
      <div className="container mx-auto px-4">
        <h2 className="text-2xl md:text-3xl font-bold text-center mb-12 underline underline-offset-8 text-green-900">What Our Students Say</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {testimonialsList.map((testimonial, index) => (
            <div key={index} className="bg-white p-6 rounded-lg shadow-md">
              <div className="mb-4">
                <p className="italic">"{testimonial.content}"</p>
              </div>
              <p className="text-right text-green-900 font-semibold">- {testimonial.author}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Testimonials;