import React from "react";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Resources from "./pages/Resources";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";



function App() {
  return (
    <div>
      <Router>
        <Navbar/>
            <Routes>
                <Route path="/" element = {<Home/>} />
                <Route path="/about" element = {<About/>} />
                <Route path="/resources" element = {<Resources/>} />
                <Route path="/contact" element = {<Contact/>} />
            </Routes>
        <Footer/>
      </Router>
    </div>
  );
}

export default App;
