import React from 'react';

const About = () => {
  const keyFeatures = [
    { title: "Own Curriculum", description: "We develop our courses to address the specific issues French speakers face when learning English, including pronunciation, grammar, and vocabulary." },
    { title: "Immersive Digital Experience", description: "Interactivity in the content, videos, and live online courses makes your learning environment very interesting and gives you the feeling of learning in a real English-speaking context." },
    { title: "Flexible Learning", description: "Learn at your own pace, at any time of day and from any location with our highly intuitive platform that runs on any device." },
    { title: "Qualified Teachers", description: "Our teachers are all qualified and recognize the specific needs of French learners. They offer everything tailored to you." },
    { title: "Progressive Learning Path", description: "A structured approach helps you build strong fundamentals and then gradually progress to higher levels of fluency." },
    { title: "Practice Opportunities", description: "Take part in conversation courses and discussion forums and do interactive exercises. This allows you to apply what you have learnt in the real world." },
    { title: "Measurable Progress", description: "Monitor your progress with regular assessments. Earn certificates when you finish in our center." },
  ];

  return (
    <section id="about" className="bg-gray-100 py-16">
      <div className="container mx-auto px-4 font-mono">
        <h2 className="text-2xl md:text-3xl text-red-900 font-bold mb-8 text-center underline underline-offset-8">About Us</h2>
        <p className="text-lg mb-8">
          The Digital English Learning Academy is designed specifically for French speakers who want to master the English language. We offer a complete online learning experience from 'zero' to 'hero' speaker.
        </p>
        <h3 className="text-2xl font-semibold mb-6 text-center">Here are some Key Features of Our Academy:</h3>
        <ul className="space-y-4 mb-8">
          {keyFeatures.map((feature, index) => (
            <li key={index} className="flex items-start">
              <span className="text-green-900 mr-2">•</span>
              <div>
                <strong className='text-green-900'>{feature.title}:</strong> {feature.description}
              </div>
            </li>
          ))}
        </ul>
        <p className="text-lg mb-4">
          Simplify your English in prestigious ways! Don't worry about theory: learn the language for real life - when traveling, at work, or for personal interest.
        </p>
        <p className="text-lg">
          Join us on this interesting journey from nothing to everything to master the English language. At the Digital English Learning Academy, you'll be able to communicate confidently and effectively in English, opening the door to a wealth of new opportunities.
        </p>
      </div>
    </section>
  );
};

export default About;